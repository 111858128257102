import "@/styles/globals.css";

import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import React, { PropsWithChildren } from "react";
import type { ToastContainerProps } from "react-toastify";

import { MetadataProvider } from "@/layouts/MetadataProvider";
import type { ReduxStoreProps } from "@/layouts/ReduxStore";

const MW = {
  ABTesting: dynamic(
    import("@/layouts/ABTesting").then((module) => module.default)
  ),
  AppInsights: dynamic(() => import("@/layouts/AppInsights")),
  CookiesProvider: dynamic(() =>
    import("@synerai/react-cookie").then((module) => module.CookiesProvider)
  ),
  DevToolbox: dynamic(() =>
    import("@/components/ui/devToolbox").then((module) => module.DevToolbox)
  ),
  Fingerprint: dynamic(() =>
    import("@/layouts/Fingerprint").then((module) => module.default)
  ),
  FusionChartsInit: dynamic(
    () =>
      import("@/components/ui/FusionChartsInit").then(
        (module) => module.default
      ),
    { ssr: false }
  ),
  GTag: dynamic(() => import("@/layouts/GTag").then((module) => module.GTag), {
    ssr: false,
  }),
  LoadingScreen: dynamic(() =>
    import("@/layouts/LoadingScreenLayout").then((module) => module.default)
  ),
  MsalLayoutWrapper: dynamic(
    import("@/layouts/MsalLayoutWrapper").then((module) => module.default)
  ),
  PageTracking: dynamic(() =>
    import("@/layouts/PageTracking").then((module) => module.default)
  ),
  Recaptcha: dynamic(() =>
    import("@/layouts/Recaptcha").then((module) => module.default)
  ),
  ReduxStore: dynamic<ReduxStoreProps>(() =>
    import("@/layouts/ReduxStore").then((module) => module.default)
  ),
  ToastContainer: dynamic<ToastContainerProps>(() =>
    import("react-toastify").then((module) => module.ToastContainer)
  ),
};

const SafeHydrate = ({ children }: PropsWithChildren) => {
  return (
    <div suppressHydrationWarning>
      {typeof window === "undefined" ? null : children}
    </div>
  );
};

const MyApp = ({ Component, pageProps }: AppProps) => (
  <>
    <MetadataProvider
      Component={Component}
      pageProps={pageProps}
      defaultTitle="Foliko by SynerAI"
    />
    <MW.ReduxStore>
      <MW.Fingerprint>
        <MW.CookiesProvider>
          <MW.AppInsights>
            <SafeHydrate>
              <MW.MsalLayoutWrapper>
                <MW.ABTesting>
                  <MW.Recaptcha>
                    <MW.GTag />
                    <MW.FusionChartsInit />
                    <MW.PageTracking />
                    <MW.ToastContainer />
                    <MW.LoadingScreen />
                    <Component {...pageProps} />
                    <MW.DevToolbox />
                  </MW.Recaptcha>
                </MW.ABTesting>
              </MW.MsalLayoutWrapper>
            </SafeHydrate>
          </MW.AppInsights>
        </MW.CookiesProvider>
      </MW.Fingerprint>
    </MW.ReduxStore>
  </>
);

export default MyApp;
