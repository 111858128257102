import Head from "next/head";
import { useRouter } from "next/router";
import { NextComponentType, NextPageContext } from "next/types";
import { jsonLdScriptProps } from "react-schemaorg";
import type { AnalysisNewsArticle, WithContext } from "schema-dts";

import { FolikoPage } from "@/model/page";
import { BaseUrl } from "@/util/app";

interface Props {
  Component: NextComponentType<NextPageContext, any, any>;
  defaultTitle?: string;
  pageProps: {};
}

export const MetadataProvider = ({
  Component,
  pageProps,
  defaultTitle,
}: Props) => {
  const router = useRouter();
  const page = Component as FolikoPage;

  if (!page.getMetadata) {
    return null;
  }

  const {
    pagePublishDate,
    pageTitle,
    pageDescription,
    pageThumbnail,
    pageUrl,
  } = page.getMetadata(pageProps);

  const title = pageTitle ? pageTitle({ pageProps, router }) : defaultTitle;
  const url = pageUrl ? pageUrl({ pageProps, router }) : "";
  const publishDate = pagePublishDate
    ? pagePublishDate({ pageProps, router })
    : undefined;
  const description = pageDescription
    ? pageDescription({ pageProps, router })
    : "";
  const thumbnail = pageThumbnail ? pageThumbnail({ pageProps, router }) : "";

  const articleSchema: WithContext<AnalysisNewsArticle> | undefined =
    publishDate
      ? {
          "@context": "https://schema.org",
          "@type": "AnalysisNewsArticle",
          articleBody: description,
          author: {
            "@type": "Organization",
            name: "Team SynerAI",
            url: "https://synerai.com/",
          },
          datePublished: publishDate.format("YYYY-MM-DD"),
          headline: title,
          image: [thumbnail],
          mainEntityOfPage: url,
          publisher: {
            "@type": "Organization",
            logo: {
              "@type": "ImageObject",
              url: "/logo.webp",
            },
            name: "Team SynerAI",
          },
        }
      : undefined;

  return (
    <Head key="metadata-provider">
      <meta property="og:type" content="website" />
      <meta property="twitter:domain" content={BaseUrl} />

      {title && (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
        </>
      )}

      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </>
      )}

      {url && (
        <>
          <meta property="og:url" content={url} />
          <meta property="twitter:url" content={url} />
          <link rel="canonical" href={url} key="canonical" />
        </>
      )}

      {thumbnail && (
        <>
          <meta property="og:image" content={thumbnail} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content={thumbnail} />
        </>
      )}

      {articleSchema && (
        <script {...jsonLdScriptProps<AnalysisNewsArticle>(articleSchema)} />
      )}
    </Head>
  );
};
